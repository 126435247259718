import {ConnectKitButton} from "connectkit";
import React from "react";
import {useAccount, useContractWrite, usePrepareContractWrite,} from "wagmi";
import {ethers} from "ethers";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from "react-confetti";
import {useDispatch, useSelector} from "react-redux";
import {selectMintedTokenOwners, setTokenOwner} from "../../../features/tokensCollection/tokensCollectionSlice";
import Analytics, {AnalyticsEvent} from "../../../analytics";


const CONFIG = require("../../../data/config.json");
const CONTRACT_INTERFACE = require("../../../data/WhatDoYouSeeNFT.json");

function ConfettiOnMint() {
  const { width, height } = useWindowSize()
  return (
    <Confetti
      width={width}
      height={height}
    />
  )
}

const analytics = new Analytics();

export default function MintButton(props: {tokenId: number}) {
  const { address, isConnected } = useAccount();

  const { config } = usePrepareContractWrite({
    addressOrName: CONFIG.CONTRACT_ADDRESS,
    contractInterface: CONTRACT_INTERFACE.abi,
    functionName: 'mint',
    args: [address, props.tokenId],
    overrides: {
      value: ethers.utils.parseEther(CONFIG.MINT_PRICE),
    }
  })
  const { data, write } = useContractWrite(config);

  const handleClick = () => {
    analytics.logEvent(AnalyticsEvent.TOKEN_MINT_CLICKED, {tokenId: props.tokenId});
    write?.();
  }

  const owners = useSelector(selectMintedTokenOwners);
  const owner = owners[props.tokenId.toString()];

  const dispatch = useDispatch();

  if(data?.hash && owners[props.tokenId.toString()] === undefined) {
    (async () => dispatch(setTokenOwner({token: props.tokenId.toString(), owner: address!})))();
  }

  if(data?.hash) {
    return <div>
      <h1 className={"text-xl mb-1 font-bold"}>Enjoy!</h1>
      Check your <a target={"_blank"} href={`https://etherscan.io/tx/${data?.hash}`}>transaction on Etherscan</a>
    </div>
  }

  if(owner && owner !== address) {
    return <div>Minted to <span className={"font-mono bg-slate-100"}>{owner}</span></div>
  }
  if(owner && owner === address) {
    return <div>Minted to you!</div>
  }

  const classes = "inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm";
  if(isConnected) {
    return <>
      <button
        type="button"
        className={classes}
        onClick={handleClick}
      >
        Mint now for {CONFIG.MINT_PRICE} ETH
      </button>
      {data?.hash && <ConfettiOnMint />}
    </>
  } else {
    return <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName }) => {
        return (
          <button onClick={() => {
            analytics.logEvent(AnalyticsEvent.TOKEN_MINT_CONNECT_CLICKED, {tokenId: props.tokenId});
            show?.()
          }} className={classes}>
            Connect wallet and mint for {CONFIG.MINT_PRICE} ETH
          </button>
        );
      }}
    </ConnectKitButton.Custom>
  }
}