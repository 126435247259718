import React, {useEffect} from 'react';
import CollectionList from "./collection/CollectionList";
import Filters from "./collection/Filters";
import OverlayDisplayOneToken from "./collection/OverlayDisplayOneToken";
import {useDispatch} from "react-redux";
import {setMintedTokenOwners} from "../../features/tokensCollection/tokensCollectionSlice";
import {Header1, Header3} from "../headers";
import { Container } from './landing/nft-announcement';

const CONFIG = require("../../data/config.json");

async function loadMintedTokens() {
  const response = await fetch(CONFIG.MINTED_API_ENDPOINT);
  const data = await response.json();
  return data;
}

export default function TokensCollection() {
  const dispatch = useDispatch()
  useEffect(() => {
    loadMintedTokens().then((t: { [key: string]: string }) => dispatch(setMintedTokenOwners(t)))
  }, []);

  return (
    <div className={"px-5 py-10 sm:px-20 sm:py-20"}>
      <Container>
        <Header3>Green moon?</Header3>
        <p className="mt-2 text-slate-700 leading-relaxed">
          Below is the full list of tokens with filters. <br />
          <i>Subject</i> is what's seen by the describer AI: "a cat", "a dog", "a knife". <br />
          <i>View</i> is what's actually on the image, i.e. a sunset, blue sky, etc. <br />
          One of the views &ndash; <i>Green moon</i> &ndash; is special: it's a glitch of GAN that produced images.
          There were more like this, but I left just one. After all, how often should you see a green moon?<br />
        </p>
        <div className={"mb-20"} />
        <Header1>The collection</Header1>
        <p className={"mt-6"}>
          Find what you like, and <b>mint it</b>! Remember that first and foremost NFT should be about supporting artists and
          helping them explore new things.<br />
        </p>
      </Container>
      <div className={"max-w-4xl mt-8 mx-auto"}>
        <Filters />
        <CollectionList />
      </div>
      <OverlayDisplayOneToken />
    </div>
  );
}
