import React, {useEffect, useState} from 'react';
import {Header1, Header2, Header3} from "../../headers";

function TwoNetworksDescription() {
  return <div>
    <h2>A custom-trained GAN is dreaming up pictures of the sky</h2>

    <h2>A pre-trained NN is describing what it sees</h2>
  </div>
}

function TokenExampleAppearing() {
  const bg = require('../../../img/tokens/1.png');
  return <div>
    <img
      className="h-full w-full object-cover"
      src={bg}
      alt=""
    />
  </div>
}

const AboutMe = () => <div className="mx-auto max-w-7xl py-5 sm:py-10 text-slate-800">
  <div className="">
    <p className="mt-1 text-slate-700 text-lg font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-3xl">
      First NFT collection by <a target="_blank" className={"text-amber-500"} href={"https://twoeight.xyz"}>twoeight</a>
    </p>
    <p className={"py-3 text-lg text-left"}>
      Be among first to join my <a href={"https://discord.gg/SUjC4C92qx"}>discord</a> and help me build more NFTs and crypto services. Or <a href={"https://twitter.com/twoeight_xyz"}>send me a DM on twitter!</a>
    </p>
  </div>
</div>

const ChangingPhrases = () => {
  const PHRASES = [
    "a black and white photo of a mountain range",
    "a black and white photo of a cat laying on a couch",
    "a picture of a large body of water",
    "a cat is sitting on a bed with a blanket",
    "a surfer is riding a wave in the ocean",
    "a picture of a bird in the air",
    "a close up of a dog in a tree",
    "an airplane flying in the sky over a mountain",
    "a cat is laying on a bed with a blanket"
  ];

  const [phraseInd, setPhraseInd] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setPhraseInd((phraseInd + 1) % PHRASES.length)
      // setPhraseInd(5)
    }, 2000);

    return () => clearInterval(interval);
  });

  const phrase = PHRASES[phraseInd];

  return <div style={{width: 256}}
              className={"align-top text-xl px-4 sm:px-6 lg:px-8 text-gray-700"}>
    &mdash; {phrase}
  </div>
}

const VideoCircle = () => {
  const videoAI = require('../../../img/ai.mp4');
  const radius = 45;
  return <video muted autoPlay playsInline loop style={{
    width: radius * 2,
    display: "inline",
    clipPath: `circle(${radius}px)`,
  }} className={"py-5"}>
    <source src={videoAI} type="video/mp4" />
  </video>
}

const sectionClasses = "lg:py-10 py-2 sm:py-5 ";

const PNGHTMLThing = () => {
  const videoScreenScale = require('../../../img/screen_scale.mp4');
  return <div className={`${sectionClasses}`}>
    <Header2>A PNG for normal NFT thigs, HTML for fun</Header2>
    <video width="100%" loop={true} muted={true}>
      <source src={videoScreenScale} type="video/mp4" />
    </video>
    <p className={"info"}>
      Every token gets a square 2048px PNG, and a HTML file with a description. <br />
      HTML file is vector graphics and adapts to any aspect ratio, which makes it perfect for presenting on any screen as well as printing.
    </p>
  </div>
}

const NftDesciption = () => <div>
  <div className={`${sectionClasses} mt-5`}>
    <Header1>A mintable art experiment</Header1>
    <p className="important-information mt-2">
      Have you ever looked at clouds and saw a cat, a horse, a face? This is like that, but with (bored) AIs.
    </p>
    <p className="important-information mt-2">
      Two neural networks interacting: one, a custom-trained GAN,
      is making pictures of the sky.
      The other is describing what it sees:
    </p>

    <div className={"items-center flex justify-center py-4"}>
      <VideoCircle />
      <ChangingPhrases />
    </div>

    <Header3>Process</Header3>
    <p className="important-information mb-2">
      To make the selection, I generated 150k sky images with descriptions. <br />
      Of course vast majority was something like “a view of the clouds in the sky”, but then
      I only kept one image per unique description, so there are <b>just 925</b>, and they
      are bizarre:
      <br />
      &mdash; a surfer is riding a wave in the ocean <br />
      &mdash; a cat is sitting on a bed with a blanket <br />
      &mdash; a close up of a dog in a tree <br />
    </p>

    <Header3>What's inside</Header3>
    <p className="important-information">
      Every token, besides a standard image, has a HTML version that adapts to any ratio
      and can be printed in huge sizes.
    </p>

    <p className="important-information mt-2">
      While not giving any on-chain utility, these pictures are beautiful and weird,
      printable in huge resolutions, and ultimately are making you wonder &mdash; what do you see?
    </p>

  </div>

  {/*<p className="important-information">*/}
  {/*  All descriptions are unique. That's why out of 150k generated images, there are <b>only 925 tokens</b>*/}
  {/*</p>*/}
</div>

const MakeWrapper = (classNames: string) => {
  return (props: {children: React.ReactNode}) => <div className={classNames}>
    {props.children}
  </div>
}

const containerClasses = "sm:px-10 px-5";
export const Container = MakeWrapper("max-w-screen-sm mx-auto py-15 " + containerClasses);
const ImportantInfo = MakeWrapper("important-information")

const DemoImages = () => {
  const images = [
    require('../../../img/usages/print.jpg'),
    require('../../../img/usages/beam.jpg'),
    require('../../../img/usages/opensea.jpg'),
  ];
  return <div className={"w-full"}>
    <Header2>In the wallet, on the wall</Header2>
    <div className={"flex"}>
      {images.map((img, i) => <div key={i}> <img src={img} /> </div>)}
    </div>
    <div className={containerClasses + " max-w-screen-sm mx-auto mt-2 text-slate-700"}>
      Besides PNG, every token comes with a HTML file,
      which is vector graphics and adapts to any aspect ratio &ndash; perfect for a screen or printing.
    </div>
  </div>
}

export default function NftAnnouncement() {
  return (
    <div>
      <Container>
        <NftDesciption/>
        <AboutMe />
      </Container>
      <DemoImages />
    </div>
  )
};
