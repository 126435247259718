import amplitude from 'amplitude-js'
import {AmplitudeClient} from "amplitude-js";

export enum AnalyticsEvent {
  PAGE_OPENED = 'Page Opened',
  TOKEN_OPENED = 'Token Opened',
  TOKEN_CLOSED = 'Token Closed',
  TOKEN_MINT_CLICKED = 'Token Mint Clicked',
  TOKEN_MINT_CONNECT_CLICKED = 'Token Mint Connect Clicked',
  WALLET_CONNECT_CLICKED = 'Wallet Connect Clicked',
}

export default class Analytics {
  amplitude: AmplitudeClient
  constructor() {
    this.amplitude = amplitude.getInstance();
    this.amplitude.init("3c09d6369c3828b2d58620c2e7af54b9");
  }

  logEvent(eventType: AnalyticsEvent, eventProperties: any = {}) {
    this.amplitude.logEvent(
      eventType as string,
      eventProperties
    )
  }
}