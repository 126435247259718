import React, {useEffect} from 'react';
import MintCountdownSection from "./mint-countdown-section";
import {ConnectKitButton} from "connectkit";
import Analytics, {AnalyticsEvent} from "../../../analytics";

function getBackgrounds() {
  const bgs = [];
  for(let i = 0; i < 3; i++) {
    bgs.push(require(`../../../img/background/${i}.jpg`));
  }
  return bgs;
}

const analytics = new Analytics();

const BACKGROUNDS = getBackgrounds();
const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = src
  })

const ConnectWalletButton = () => {
  const classes = "max-w-xs inline-flex w-full justify-center rounded-md border border-transparent " +
    "bg-slate-100 hover:bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-900 shadow-sm " +
    "focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-md";
  return <ConnectKitButton.Custom>
    {({ isConnected, isConnecting, show, hide, address, ensName }) => {
      return (
        <button onClick={() => {
          analytics.logEvent(AnalyticsEvent.WALLET_CONNECT_CLICKED);
          show?.()
        }} className={classes}>
          {isConnected ? <span className={"font-mono truncate"}>{address}</span> : "Connect wallet"}
        </button>
      );
    }}
  </ConnectKitButton.Custom>
}

export default function HeroFirstSection() {
  const [bgIndex, setBgIndex] = React.useState(0);

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <div
          className="h-full w-full object-cover"
          style={{
            backgroundImage: `url(${BACKGROUNDS[bgIndex]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
            transition: "all .2s ease-in-out"
          }}
        />
        <div className="absolute inset-0" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl h-screen sm:py-32 sm:px-6 lg:px-8">
        <div className="absolute text-center inset-x-0 bottom-20">
          <h1 className="text-4xl font-bold tracking-tight text-white text-center sm:text-5xl lg:text-8xl">
            <span className={"p-5"} style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>What do you see</span>
          </h1>
        </div>
        <div className="absolute px-5 text-white text-right inset-x-0 top-10 right-0">
          <ConnectWalletButton />
        </div>
      </div>
    </div>
  )
}