import { combineReducers } from 'redux';
import { History } from 'history';
// eslint-disable-next-line import/no-cycle
import tokensCollectionReducer from './features/tokensCollection/tokensCollectionSlice';

export default function createRootReducer(history: History) {
  return combineReducers({
    tokensCollection: tokensCollectionReducer
  });
}
