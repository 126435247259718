import React from 'react';
import Landing from "./components/pages/landing";
import Mint from "./components/EthProvider";
import TokensCollection from "./components/pages/tokensCollection";
import {TokenDisplay} from "./components/pages/TokenDisplay";


function App() {
  const path = window.location.pathname + "#" + window.location.hash;

  if(path.includes("open/")) {
    const after = path.split("open/");
    const tokenId = parseInt(after[1]);
    if(tokenId && tokenId > 0 && tokenId <= 925) {
      return <TokenDisplay tokenId={tokenId}/>
    }
  }
  return (
    <Landing />
  );
}

export default App;
