import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';
import {TokenDescriptor, TOKENS_ALL} from "../../data/TokenCollection";

export enum MintedState {
  Minted = "Minted",
  NotMinted = "Not minted yet",
}

interface TokensCollectionFilters {
  view: string | null,
  subject: string | null,
  displayFirst: number,
  minted: MintedState | null,
}

export interface TokensCollectionState {
  filters: TokensCollectionFilters,
  allTokens: TokenDescriptor[],
  filteredTokens: TokenDescriptor[],
  overlayToken: TokenDescriptor | null,
  mintedTokenOwners: { [key: string]: string },
}

function applyFilters(state: TokensCollectionState): TokenDescriptor[] {
  const { filters, allTokens, mintedTokenOwners } = state;
  let tokens = allTokens

  if (filters.view) {
    tokens = tokens.filter(token => token.attributes.find(
      attr => attr.trait_type === 'view' && attr.value === filters.view
    ))
  }

  if (filters.subject) {
    tokens = tokens.filter(token => token.attributes.find(
      attr => attr.trait_type === 'subject' && attr.value === filters.subject
    ))
  }

  if (filters.minted) {
    if(filters.minted === MintedState.Minted) {
      tokens = tokens.filter(token => mintedTokenOwners[token.id.toString()])
    } else {
      tokens = tokens.filter(token => !mintedTokenOwners[token.id.toString()])
    }
  }

  return tokens
}

const DEFAULT_DISPLAY_FIRST = 15;
const DISPLAY_FIRST_INCREMENT = 15;

const tokensCollectionSlice = createSlice({
  name: 'tokensCollection',
  initialState: {
    filters: {
      view: null,
      subject: null,
      displayFirst: DEFAULT_DISPLAY_FIRST
    } as TokensCollectionFilters,
    allTokens: TOKENS_ALL,
    filteredTokens: TOKENS_ALL,
    overlayToken: null,
    mintedTokenOwners: {}
  } as TokensCollectionState,
  reducers: {
    setFilterView: (state, action: PayloadAction<string | null>) => {
      state.filters.view = action.payload;
      state.filteredTokens = applyFilters(state);
      state.filters.displayFirst = DEFAULT_DISPLAY_FIRST;
    },

    setFilterSubject: (state, action: PayloadAction<string | null>) => {
      state.filters.subject = action.payload;
      state.filteredTokens = applyFilters(state);
      state.filters.displayFirst = DEFAULT_DISPLAY_FIRST;
    },

    setFilterMinted: (state, action: PayloadAction<MintedState | null>) => {
      state.filters.minted = action.payload;
      state.filteredTokens = applyFilters(state);
      state.filters.displayFirst = DEFAULT_DISPLAY_FIRST;
    },

    increaseDisplayFirst: (state) => {
      state.filters.displayFirst += DISPLAY_FIRST_INCREMENT;
    },

    resetDisplayFirst: (state) => {
      state.filters.displayFirst = DEFAULT_DISPLAY_FIRST;
    },

    setOverlayToken: (state, action: PayloadAction<TokenDescriptor | null>) => {
      state.overlayToken = action.payload;
    },

    setMintedTokenOwners: (state, action: PayloadAction<{[k: string]: string}>) => {
      state.mintedTokenOwners = action.payload;
      state.filteredTokens = applyFilters(state);
    },

    setTokenOwner: (state, action: PayloadAction<{token: string, owner: string}>) => {
      state.mintedTokenOwners[action.payload.token] = action.payload.owner;
      state.filteredTokens = applyFilters(state);
    }
  },
});

export const {
  setFilterSubject,
  setFilterView,
  increaseDisplayFirst,
  resetDisplayFirst,
  setMintedTokenOwners,
  setOverlayToken,
  setTokenOwner,
  setFilterMinted
} = tokensCollectionSlice.actions;

export default tokensCollectionSlice.reducer;

export const selectDisplayedTokens = (state: RootState) => state.tokensCollection.filteredTokens.slice(
  0, state.tokensCollection.filters.displayFirst
);
export const selectTotalDisplayedTokensCount = (state: RootState) => state.tokensCollection.filteredTokens.length;
export const selectFilters = (state: RootState) => state.tokensCollection.filters;
export const selectOverlayToken = (state: RootState) => state.tokensCollection.overlayToken;
export const selectMintedTokenOwners = (state: RootState) => state.tokensCollection.mintedTokenOwners;
