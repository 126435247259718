import React from 'react';
import {TOKENS_ALL_SUBJECTS, TOKENS_ALL_VIEWS} from "../../../data/TokenCollection";
import OneSelectFilter from "./OneSelectFilter";
import {useDispatch, useSelector} from "react-redux";
import {
  MintedState,
  selectFilters, setFilterMinted,
  setFilterSubject,
  setFilterView
} from "../../../features/tokensCollection/tokensCollectionSlice";

const FilterHeader = (props: { title: string }) => {
  return <h2 className="text-md font-medium text-gray-900 ml-4">{props.title}</h2>
}

export default function Filters() {
  const {subject, view, minted} = useSelector(selectFilters);
  const dispatch = useDispatch();
  return <div>
    <div className={""}>
      <OneSelectFilter title={"Minted"} options={[MintedState.Minted, MintedState.NotMinted]} current={minted} onSelect={(v) => dispatch(setFilterMinted(v as MintedState))} />
      <OneSelectFilter title={"Subject"} options={TOKENS_ALL_SUBJECTS} current={subject} onSelect={(v) => dispatch(setFilterSubject(v))} />
      <OneSelectFilter title={"View"} options={TOKENS_ALL_VIEWS} current={view} onSelect={(v) => dispatch(setFilterView(v))} />
    </div>
  </div>;
}
