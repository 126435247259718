import React, {useEffect, useState} from 'react'
import {Header2, Header3} from "../headers";

const CONFIG = require('../../data/config.json');

const fetchMetadata = async (tokenId: number) => {
  if(!CONFIG.DEV) {
    const resp = await fetch(
      `https://whatdoyousee.art/tokens/${tokenId}.json`,
      {
        mode: 'no-cors'
      }
    );
    const json = await resp.json();
    return json;
  }
  return {
    "animation_url": "ipfs://bafybeial33vtlhzymqznr6ox57ozattkdin76tarnjoorzlrcazz5bqqiy/tokens/100.html",
    "image": "ipfs://bafybeial33vtlhzymqznr6ox57ozattkdin76tarnjoorzlrcazz5bqqiy/tokens/100.png",
    "external_url": "https://token.whatdoyousee.art/open/100",
    "description": "I see _a close up of a person on a large rock_",
    "name": "Look #100",
    "attributes": [
      { "trait_type": "subject", "value": "Human" },
      { "trait_type": "caption_frequency", "value": "lonely seed" },
      { "trait_type": "view", "value": "About to rain" }
    ]
  }
}

export function TokenDisplay(props: {tokenId: number}) {
  const [metadata, setMetadata] = useState<any>(null);
  const [showMetadata, setShowMetadata] = useState<boolean>(false);

  useEffect(() => {
    fetchMetadata(props.tokenId).then((json) => {
      setMetadata(json);
    });
  }, [props.tokenId])

  if(!metadata) {
    return <div>Loading...</div>
  }

  return <div className={"mx-auto max-w-2xl mt-10"}>
    <div>
      <Header3>Look #{props.tokenId}</Header3>
      <img
        src={`https://whatdoyousee.art/tokens/${props.tokenId}.png`}
        className={"rounded-lg"}
      />

      <div onClick={() => setShowMetadata(!showMetadata)} className={"mt-10 underline cursor-pointer hover:text-green-500"}>
        Metadata
      </div>
      {showMetadata ?
      <div className={"font-mono mb-10 text-xs overflow-scroll h-30"}>
        <pre>
          {JSON.stringify(metadata, null, 2)}
        </pre>
      </div> : null}
    </div>
  </div>
}
