import React, {useEffect} from 'react';
import HeroFirstSection from "./landing/hero-first-section";
import NftAnnouncement from "./landing/nft-announcement";
import Footer from "./landing/footer";
import TokensCollection from "./tokensCollection";
import Analytics, {AnalyticsEvent} from "../../analytics";

export default function Landing() {
  useEffect(() => {
    new Analytics().logEvent(
      AnalyticsEvent.PAGE_OPENED
    );
  }, []);
  return (
    <div>
      <HeroFirstSection />
      <NftAnnouncement />
      <TokensCollection />
      <Footer />
    </div>
    // <div style={{
    //   backgroundImage: `url("${prelogo}")`,
    //   width: 1600,
    //   height: 800,
    //   backgroundPositionY: "bottom",
    //   backgroundSize: 'cover'
    // }} className={"flex items-center"}>
    //   {/*<div*/}
    //   {/*  className={"mx-auto text-center"}*/}
    //   {/*  style={{ fontSize: 256, color: "white", fontWeight: "bold"}}*/}
    //   {/*>*/}
    //   {/*  <span style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '1vh'}}>WDYS</span>*/}
    //   {/*</div>*/}
    //   {/*<HeroFirstSection />*/}
    //   {/*<NftAnnouncement />*/}
    //   {/*<Footer />*/}
    //
    //
    // </div>
  );
}
