import React, {HTMLAttributes, SVGProps} from 'react';

const PathWrapper = (props: {children: React.ReactNode}) => <svg fill="currentColor" width={48} height={48} viewBox="0 0 24 24">
  {props.children}
</svg>

const navigation = {
  social: [
    {
      name: 'Discord',
      href: 'https://discord.gg/SUjC4C92qx',
      icon: (props: HTMLAttributes<SVGProps<any>>) => (
        <PathWrapper>
          <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm-3.288-4.888l-.424-1.48 1.024.952.968.896 1.72 1.52v-14.352c0-.912-.736-1.648-1.64-1.648h-10.72c-.904 0-1.64.736-1.64 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072zm-1.44-3.664c1.768-.056 2.448-1.216 2.448-1.216 0-2.576-1.152-4.664-1.152-4.664-1.152-.864-2.248-.84-2.248-.84l-.112.128c1.36.416 1.992 1.016 1.992 1.016-.832-.456-1.648-.68-2.408-.768-.576-.064-1.128-.048-1.616.016l-.136.016c-.28.024-.96.128-1.816.504l-.472.232s.664-.632 2.104-1.048l-.08-.096s-1.096-.024-2.248.84c0 0-1.152 2.088-1.152 4.664 0 0 .672 1.16 2.44 1.216l.536-.664c-1.016-.304-1.4-.944-1.4-.944l.224.136.032.024.032.018.009.004.031.018c.2.112.4.2.584.272.328.128.72.256 1.176.344.6.112 1.304.152 2.072.008.376-.064.76-.176 1.16-.344.28-.104.592-.256.92-.472 0 0-.4.656-1.448.952l.528.648zm-3.72-3.736c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z"/>
        </PathWrapper>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/twoeight_xyz',
      icon: (props: HTMLAttributes<SVGProps<any>>) => (
        <PathWrapper>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </PathWrapper>
      ),
    },

  ],
}

export default function Footer() {
  // @ts-ignore
  return (
    <footer className="bg-slate-300 mt-20">
      <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item) => {
            const Icon = item.icon
            return <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <Icon className="h-6 w-6" aria-hidden="true"/>
            </a>
          })}
          <a href="https://raritysniper.com/nft-drops-calendar" className={"text-slate-700"}>NFT Drops</a>
          <a href="https://icy.tools/calendar" target="_blank"> <img src="https://cdn.icy.tools/images/icy-tools-light.svg" style={{width: 100}} alt="as seen on icy.tools" width="256" /></a>
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; <a href={"https://twoeight.xyz"} className={"text-slate-700"}>twoeight</a>
        </p>
      </div>
    </footer>
  )
}
