interface Props {
  title: string;
  options: string[],
  current: string | null,
  onSelect: (option: string | null) => void,
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ALL = " - All - ";

export default function OneSelectFilter(props: Props) {
  return (
    <div className={"mb-2"}>
      <div className="hidden">
        <label htmlFor={props.title} className="">
          {props.title}
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id={props.title}
          name={props.title}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => props.onSelect(e.target.value === ALL ? null : e.target.value)}
          defaultValue={props.current || "-"}
        >
          {[ALL].concat(props.options).map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="mt-3">
        <div className={"py-2 text-sm sm:hidden"}>
          {props.title}:
        </div>
        <div className="flex space-x-2 overflow-hidden flex-wrap" aria-label="Tabs">
          <div className={"py-2 text-sm hidden sm:block text-slate-700"}>
            {props.title}:
          </div>
          {props.options.map((option) => (
            <a
              key={option}
              onClick={() => props.onSelect(option === props.current ? null : option)}
              className={classNames(
                option === props.current ? 'bg-indigo-100 text-indigo-800' : 'text-gray-700 hover:text-gray-700',
                'px-2 py-2 font-medium text-sm rounded-md cursor-pointer'
              )}
              aria-current={option === props.current ? 'page' : undefined}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}