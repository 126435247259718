import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {configuredStore} from "./store";
import {Provider} from "react-redux";
import EthProvider from "./components/EthProvider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://5ad8d39ca09540199a56a829c140fee8@o244219.ingest.sentry.io/4504135353761792",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configuredStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <EthProvider>
        <App />
      </EthProvider>
    </Provider>
  </React.StrictMode>
);
