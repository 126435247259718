import {useDispatch, useSelector} from "react-redux";
import {
  increaseDisplayFirst,
  selectDisplayedTokens, selectMintedTokenOwners,
  selectTotalDisplayedTokensCount, setOverlayToken
} from "../../../features/tokensCollection/tokensCollectionSlice";
import TokenDescription from "./tokenDescription";
import {TokenDescriptor} from "../../../data/TokenCollection";

function ZeroDataState() {
  return <div className={"flex flex-col items-center h-96 justify-center"}>
    <div className={"text-2xl text-gray-500 leading-loose"}>
      No tokens with these filters :(
      <br />try relaxing them, surely you'll find something
    </div>
  </div>
}

export default function CollectionList() {
  const tokens = useSelector(selectDisplayedTokens);
  const dispatch = useDispatch();
  const totalCount = useSelector(selectTotalDisplayedTokensCount);
  const canLoadMore = tokens.length < totalCount;
  const selectToken = (token: TokenDescriptor) => dispatch(setOverlayToken(token));
  const owners = useSelector(selectMintedTokenOwners);
  return <div className={"mt-5"}>
    <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-8">
      {tokens.map((token) => (
        <li key={token.id} className="relative">
          <div
            onClick={() => selectToken(token)}
            className="group aspect-w-10 aspect-h-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img src={token.imageUrl} alt="" className="pointer-events-none object-cover group-hover:scale-125 transition-transform" />
            <button type="button" className="absolute inset-0 focus:outline-none">
              <span className="sr-only">View {token.name}</span>
            </button>
          </div>
          <a onClick={() => selectToken(token)} className={"no-underline"}>
            <div className={"flex place-content-between"}>
              <div className="mt-2 block text-sm font-medium text-gray-500">
                {token.name}
              </div>
              <div className={"rounded-md mt-2 px-2 text-sm bg-slate-500 text-white"}>
                {owners[token.id.toString()] ? "minted" : "ready to mint"}
              </div>
            </div>
            <p className="block mt-2 text-sm font-medium text-gray-900 cursor-pointer" onClick={() => selectToken(token)}>
              <TokenDescription description={token.description} />
            </p>
          </a>
        </li>
      ))}
    </ul>
    {tokens.length === 0 ? <ZeroDataState /> : null}
    {canLoadMore ?
      <a onClick={(d) => dispatch(increaseDisplayFirst())}
         className="cursor-pointer mt-8 block text-center text-sm font-medium text-indigo-600 hover:text-indigo-500">
        showing {tokens.length} / {totalCount}: load more
      </a> : null}
  </div>
}