import {WagmiConfig, createClient, chain} from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import React from "react";

const INFURA_ID = "0f7193b7a36c483fb4559991ab420f5f";

const client = createClient(
  getDefaultClient({
    appName: "What Do You See",
    infuraId: INFURA_ID,
    chains: [chain.mainnet]
  }),
);

console.log(client);

export default function EthProvider(props: {children: React.ReactNode}) {
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>
        {props.children}
      </ConnectKitProvider>
    </WagmiConfig>
  );
};
