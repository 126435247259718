export interface TokenAttribute {
  trait_type: string;
  value: string;
}

export interface TokenDescriptor {
  id: number;
  name: string;
  imageUrl: string;
  htmlUrl: string;
  description: string;
  attributes: TokenAttribute[]
}

export const TOKENS_ALL_BY_ID: {[token: string]: TokenDescriptor} = require("./tokens.json");
export const TOKENS_ALL: TokenDescriptor[] = Object.values(TOKENS_ALL_BY_ID);

export const TOKENS_ALL_VIEWS: string[] = [];
export const TOKENS_ALL_SUBJECTS: string[] = [];

for (let token in TOKENS_ALL_BY_ID) {
  const descriptor = TOKENS_ALL_BY_ID[token];
  descriptor.imageUrl = `https://whatdoyousee.art/tokens/${token}.png`;
  descriptor.htmlUrl = `https://whatdoyousee.art/tokens/${token}.html`;
  for (let attr of descriptor.attributes) {
    if (attr.trait_type === 'view') {
      if (!TOKENS_ALL_VIEWS.includes(attr.value)) {
        TOKENS_ALL_VIEWS.push(attr.value);
      }
    } else if (attr.trait_type === 'subject') {
      if (!TOKENS_ALL_SUBJECTS.includes(attr.value)) {
        TOKENS_ALL_SUBJECTS.push(attr.value);
      }
    }
  }
}
