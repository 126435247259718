import React, {useEffect} from 'react';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from "react-redux";
import {
  selectMintedTokenOwners,
  selectOverlayToken,
  setOverlayToken
} from "../../../features/tokensCollection/tokensCollectionSlice";
import TokenDescription from "./tokenDescription";
import MintButton from "./MintButton";
import {useAccount} from "wagmi";
import Analytics, {AnalyticsEvent} from "../../../analytics";

const analytics = new Analytics();

export default function OverlayDisplayOneToken() {
  const token = useSelector(selectOverlayToken);
  const dispatch = useDispatch();

  const owners = useSelector(selectMintedTokenOwners);
  const close = () => dispatch(setOverlayToken(null))

  const { address, isConnected } = useAccount();
  const mintedTo = owners[token?.id.toString() || ""];
  const isYours = isConnected && address && mintedTo === address;

  useEffect(() => {
    if(token) {
      analytics.logEvent(AnalyticsEvent.TOKEN_OPENED, {tokenId: token.id});
    } else {
      // analytics.logEvent(AnalyticsEvent.TOKEN_CLOSED);
    }
  }, [token?.id]);

  return (
    <Transition.Root show={token != null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={"relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 " +
                "text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-2xl sm:p-6"}>
                <div>
                  {/*<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">*/}
                  {/*  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />*/}
                  {/*</div>*/}
                  <div className="">
                    <p className="text-sm text-gray-500 text-right">
                      <a href={token?.htmlUrl} target={"_blank"} className={"text-slate-400 items-end place-items-end"}>
                        Open as HTML
                      </a>
                    </p>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {token?.name} {isYours ? <i>(yours)</i> : null}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 text-left p-2 mb-2">
                        <TokenDescription description={token?.description || ""} />
                      </p>
                    </div>

                  </div>
                </div>
                <div>
                  <img
                    src={token?.imageUrl}
                    alt={token?.description || ""}
                    className="rounded-md pointer-events-none object-cover" />
                </div>
                <div className="mt-5 sm:mt-6">
                  <MintButton tokenId={token?.id || 0} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
