import React from 'react';

export function Header1(props: {children: React.ReactNode}) {
  return <h1 className="text-4xl mb-6 font-bold tracking-tight text-center sm:text-5xl lg:text-5xl">
    {props.children}
  </h1>
}

export function Header2(props: {children: React.ReactNode}) {
  return <h2 className="text-slate-800 text-3xl mb-4 font-semibold tracking-tight text-center sm:text-3xl lg:text-4xl">
    {props.children}
  </h2>
}

export function Header3(props: {children: React.ReactNode}) {
  return <h3 className="text-xl mb-1 mt-3 font-semibold text-slate-500 tracking-tight text-left sm:text-2xl lg:text-2xl">
    {props.children}
  </h3>
}